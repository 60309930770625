#awards-main {
  margin-top: 140px;
  color: #313131;

  .title {
    text-align: start;
    color: #141414;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.25;
  }

  .container {
    display: flex;
    flex-direction: column;
    .btn-action {
      cursor: pointer;
      border-radius: 100px;
      background: transparent;
      border: 2px solid #ff8008;
      display: inline-flex;
      padding: 20px 40px;
      justify-content: center;
      align-items: center;
      color: #ff8008;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.4px;
      margin-top: 30px;
      align-self: center;

      &:hover {
        background: linear-gradient(
          90deg,
          #ff8008 0%,
          #fd8c06 33.33%,
          #fb9904 66.67%,
          #f8b300 100%
        );
        color: #ffffff;
      }
    }
  }

  .wrapper-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-top: 40px;

    .card-item {
      background: #ffffff;
      border-radius: 20px;
      -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
      text-align: start;
      padding: 20px;
      z-index: 1;

      .item-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.3;
        margin: 26px 0px 10px;
        letter-spacing: -0.2px;
      }

      .item-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.3;
        margin: 0px;
      }
    }
  }

  @media (max-width: 1200px) {
    .title {
      font-size: 38px;
    }
  }

  @media (max-width: 991px) {
    .wrapper-cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 780px) {
    .wrapper-cards {
      grid-template-columns: repeat(1, 1fr);

      .card-item {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        .item-title {
          margin-top: 0;
        }
      }
    }
  }

  @media (max-width: 580px) {
    .title {
      font-size: 32px;
    }
  }
}
