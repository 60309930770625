.case-page {
  h2 {
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  #header-case {
    .bg-header {
      width: 100%;
      // background: url(../../../public/img/bg-min.webp) top;
      // background-size: 100% 42%;
      // background-repeat: no-repeat;
      background-color: transparent;
    }
    .header-case-wrap {
      margin-top: 150px;
      display: block;
      position: relative;
      .case-item {
        display: flex;
        .case-item_img {
          flex: 49% 0 0;
          margin-top: 170px;
          border-radius: 20px;
          img {
            border-radius: 20px;
          }
        }

        ul li {
          list-style-type: none;
        }
        ul li::before {
          color: var(--bg-color);
          content: ".";
          font-size: 50px;
          padding-right: 10px;
        }
        ol li::before {
          color: var(--bg-color);
          content: ".";
          font-size: 50px;
          padding-right: 10px;
        }
      }
    }
  }
  .case-section {
    h2 {
      font-size: 2rem;
    }
  }

  .lightbox {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity ease 0.4s;
  }

  .show-img {
    visibility: visible;
    opacity: 1;
  }
  // .zoom {
  //   position: fixed;
  //   z-index: 9999;
  //   top: 0;
  //   left: 0;
  //   width: 100vw;
  //   height: 100vh;
  //   background: rgba(0, 0, 0, 0.5);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  // .zoom img {
  //   height: 100%;
  //   width: 100%;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   object-fit: contain;
  //   z-index: 5;
  //   cursor: pointer;
  // }

  .lightbox-modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity ease 0.4s;
  }
  .mobile-store {
    display: none;
  }
  .show-img-modal {
    visibility: visible;
    opacity: 1;
    z-index: 5;
  }
  .setup_left-column {
    flex: 270px 0 0;
    max-width: 270px;
    position: relative;
    .setup-sidebar {
      padding-top: 30px;
    }

    .setup-sidebar_menu {
      font-weight: 500;
      font-size: 0.875rem;
      margin-bottom: 2.4rem;
    }

    .setup_item-box {
      margin-bottom: 20px;
    }

    .setup_item-box img {
      border-radius: 20px;
    }

    .setup-sidebar_tags {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
    }

    .setup-sidebar_menu {
      font-weight: 500;
      font-size: 0.875rem;
      margin-bottom: 2.4rem;
      li {
        margin-bottom: 0.7rem;
        display: flex;
      }
      .link-sidebar-item {
        display: flex;
        align-items: center;
        color: inherit;
        border-bottom: none;
      }
      .link-sidebar-item.active {
        font-weight: 600;
      }
      a.active {
        font-weight: 600;
      }

      a {
        display: flex;
        align-items: center;
        color: inherit;
        border-bottom: none;
      }

      a.active:before {
        width: 4px;
        margin-right: 0.6rem;
      }

      a:before {
        content: "";
        display: block;
        height: 100%;
        background: #ff8008;
        border-radius: 4px;
        transition: width 0.3s, margin-right 0.3s;
      }
      .link-sidebar-item.active:before {
        width: 4px;
        margin-right: 0.6rem;
      }

      .link-sidebar-item:before {
        content: "";
        display: block;
        height: 100%;
        background: #ff8008;
        border-radius: 4px;
        transition: width 0.3s, margin-right 0.3s;
      }

      ol,
      ul {
        list-style: none;
        margin-bottom: 0;
      }
    }

    .setup-sidebar_tags {
      .item-tag {
        display: flex;
        align-items: center;
        font-weight: 500;
        height: 29px;
        background: #fff2e7;
        border-radius: 10px;
        padding: 0.3rem 0.7rem;
        color: #ff8008;
        height: 44px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      a {
        color: #ff8008;
      }
    }

    .setup_item-box p:last-child {
      margin-bottom: 0;
    }
  }

  #block-0 {
    // margin-top: -125px;
    // padding-bottom: 125px;
    display: block;
  }

  #block-1 {
    // margin-top: -125px;
    // padding-bottom: 125px;
    display: block;
  }

  #block-2 {
    // margin-top: -125px;
    // padding-bottom: 125px;
    display: block;
    z-index: -1;
  }

  #block-3 {
    // margin-top: -125px;
    // padding-bottom: 125px;
    display: block;
  }

  #block-4 {
    margin-top: -125px;
    padding-bottom: 125px;
    display: block;
  }

  #tech-section {
    // margin-top: -125px;
    // padding-bottom: 125px;
    display: block;
  }

  .case-item-header {
    background-size: cover;
  }

  .case-item_desc {
    flex: 1 0 0;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    margin-top: 170px;
  }

  .case_main-title {
    color: #111111;
    font-size: 44px;
    font-weight: 800;
    line-height: 50px;
    text-align: left;
    margin-top: 1.5rem;
  }
  .case-item_desc_logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .case-item_title {
    display: block;
    font-size: 40px;
    font-weight: 800;
    padding-bottom: 50px;
    margin-bottom: 1rem;
    line-height: 1.2;
    color: white;
  }

  // .case-height {
  //   transition: all 2s ease-in-out 0s;
  //   display: flex;
  //   flex-direction: row;
  // }

  .case-img {
    z-index: 5;
    position: absolute;
    top: 24px;
    left: 9px;
    width: 270px;
    height: 489px;
  }
  .case-item_logo {
    img {
      max-height: 41px;
    }
  }

  .case-item_logo {
    padding-right: 15px;
  }
  .block-img {
    img {
      cursor: pointer;
    }
  }
  .case-item_app {
    font-family: Montserrat;
    font-size: 44px;
    font-weight: 800;
    line-height: 52px;
    color: white;
    text-align: left;
  }

  .case-img_row {
    img {
      cursor: pointer;
    }
  }

  @media (max-width: 991px) {
    .mobile-store {
      display: block;
    }
    .case-item_desc {
      display: block;
      padding: 0px;
    }
    .case-section {
      text-align: left;
      h2 {
        font-size: 1.5rem;
        text-align: left;
      }
    }
    .case-item_desc {
      padding: 0;
      margin-top: 10px;
    }

    .case-item_title {
      margin-top: 20px;
      color: black;
      font-size: 26px;
    }

    .case_main-title {
      color: #111111;
      font-size: 30px;
      font-weight: 800;
      line-height: 33px;
      letter-spacing: 0.02em;
      text-align: left;
    }
    .case-item_app {
      font-size: 40px;
      color: black;
    }
    #header-case {
      .container {
        width: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .navbar {
        right: 47px;
      }
      .shapes-container {
        top: -13px;
      }
      .header-case-wrap {
        .case-item {
          display: block;
          .case-item_img {
            margin-top: 126px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .case-item_app {
      font-size: 40px;
      color: black;
    }
    table {
      font-size: 12px;
    }
  }
}

.tag-ul {
  flex-direction: row;
  display: flex;
  z-index: 1;
  width: 100%;

  .active {
    background: linear-gradient(241.56deg, #ff8008 100% #ffc837 100%);
    border-radius: 10px;
    border: 2px solid transparent;
    border-radius: 10px;
    background: linear-gradient(to right, white, white),
      linear-gradient(#ff8008 -0.07%, #ffc837 64.47%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    color: var(--bg-color);
  }
}
.case-section_body {
  display: flex;
}

.setup_left-column {
  flex: 270px 0 0;
  max-width: 270px;
  position: relative;
}

.setup_right-column {
  flex: 1 0 0;
  padding-left: 3.8rem;
  padding-top: 8px;
}

.setup-sidebar {
  &.is-sticky {
    position: fixed;
    top: 7em;
    width: 270px;
  }

  &.is-static {
    position: absolute;
    bottom: 0;
    top: auto !important;
  }
}

.setup_right-column {
  ul li::before,
  ol li::before {
    color: var(--bg-color);
    content: ".";
    font-size: 50px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 991px) {
  img {
    margin-top: 10px;
    cursor: pointer;
  }

  .case-section_body {
    display: block;
  }

  .setup_left-column {
    display: none;
  }

  .setup_right-column {
    padding-left: 0px;
  }
}
video {
  width: 100%;
}
.lightbox-modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease 0.4s;
}
.mobile-store {
  display: none;
}
.show-img-modal {
  visibility: visible;
  opacity: 1;
}
.zoom-img {
  // height: 100vh;
  // width: 100vh;
  height: 100vh;
  // width: 90%;
  margin-left: auto;
  margin-right: auto;
  // margin-top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  cursor: pointer;
  z-index: 99999;
  border-radius: 20px;
  object-fit: contain;
  overflow: hidden;
  @media (max-width: 991px) {
    width: 100%;
    // margin-top: 150px;
  }
}
